var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main',[_c('v-card',{attrs:{"flat":""}},[_c('v-card-title',[_vm._v(" Sales Report "),_c('v-btn',{staticClass:"ml-3",attrs:{"icon":"","disabled":_vm.sales.loading},on:{"click":_vm.getSales}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiReload))])],1)],1),_c('v-card-text',[_c('v-data-table',{staticClass:"text-no-wrap",attrs:{"headers":_vm.tableColumns,"footer-props":{
          'items-per-page-options': [10, 10],
          'disable-items-per-page': true,
          'disable-pagination': _vm.sales.loading
        },"items":_vm.sales.list,"options":_vm.sales.options,"page":_vm.sales.meta.page,"server-items-length":_vm.sales.meta.total || 0,"loading":_vm.sales.loading,"no-data-text":'No data available',"loading-text":'Loading, pls wait'},on:{"update:options":function($event){return _vm.$set(_vm.sales, "options", $event)},"pagination":function($event){return _vm.changePagination($event)}},scopedSlots:_vm._u([{key:"item.createdAt",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.createdAt))+" ")]}},{key:"item.payout",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.amount)+" "+_vm._s(item.offer && item.offer.token)+" "+_vm._s(item.offer && item.offer.blockchain)+" ")]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
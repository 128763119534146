<template>
  <main>
    <v-card flat>
      <v-card-title>
        Sales Report

        <v-btn icon class="ml-3" @click="getSales" :disabled="sales.loading"><v-icon>{{ icons.mdiReload }}</v-icon></v-btn>
      </v-card-title>

      <v-card-text>
        <v-data-table
          :headers="tableColumns"
          :footer-props="{
            'items-per-page-options': [10, 10],
            'disable-items-per-page': true,
            'disable-pagination': sales.loading
          }"
          :items="sales.list"
          :options.sync="sales.options"
          :page="sales.meta.page"
          :server-items-length="sales.meta.total || 0"
          :loading="sales.loading"
          @pagination="changePagination($event)"
          :no-data-text="'No data available'"
          :loading-text="'Loading, pls wait'"
          class="text-no-wrap"
        >
          <template #[`item.createdAt`]="{item}">
            {{ formatDate(item.createdAt) }}
          </template>

          <template #[`item.payout`]="{item}">
            {{ item.amount }} {{item.offer && item.offer.token}} {{item.offer && item.offer.blockchain}}
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </main>
</template>

<script>
import { mdiInformation, mdiReload, mdiFileEdit, mdiShieldLock } from '@mdi/js'
import moment from 'moment'

export default {
  data: () => ({
    icons: { mdiInformation, mdiReload, mdiFileEdit, mdiShieldLock },
    sales: {
      loading: false,
      docs: [],
      meta: {},
      options: {
        sortBy: ['id'],
        sortDesc: [true],
        itemsPerPage: 10,
      },
    },
  }),
  computed: {
    tableColumns() {
      return [
        { text: 'DATE', value: 'createdAt', align: 'start', sortable: false },
        { text: 'PROJECT', value: 'project.name', sortable: false },
        { text: 'OFFER', value: 'offer.name', sortable: false },
        { text: 'PAYOUT', value: 'payout', sortable: false },
        { text: 'STATUS', value: 'status', sortable: false },
      ]
    },
  },
  methods: {
    getSales(query) {
      this.sales.loading = true

      const params = {
        limit: this.sales.options.itemsPerPage,
        query: JSON.stringify(query),
        sort: { createdAt: 'desc' },
        ...params,
      }

      this.$http
        .get('/publisher/sales', { params })
        .then(({ data }) => {
          const { meta, docs } = data

          this.sales.meta = meta
          this.sales.list = docs
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => {
          this.$nextTick(() => {
            this.sales.loading = false
          })
        })
    },

    changePagination(pagination) {
      this.getSales({
        page: pagination.page,
      })
    },

    formatDate(date) {
      return moment(date).format('MMMM Do YYYY, h:mm:ss a')
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
